/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/layout/Layout'
import NavLink from 'components/shared/NavLink'

// Third Party
import Levenshtein from 'js-levenshtein'

import Computer from 'img/computer.inline.svg'

interface Props {
  location: {
    pathname: string
  }
}

type LevenShteinList = Array<{
  uri: string
  title: string
  score: number
}>

const NotFoundPage: React.FC<Props> = ({ location }) => {
  // @ts-ignore
  const query = useStaticQuery<GatsbyTypes.notfoundQuery>(graphql`
    query notfound {
      allWpPage {
        nodes {
          title
          uri
        }
      }
    }
  `)

  const [list, setList] = React.useState<LevenShteinList>([])

  React.useEffect(() => {
    const levenshteinList: LevenShteinList = []

    query.allWpPage.nodes.forEach((n: any) => {
      levenshteinList.push({
        uri: n.uri,
        title: n.title,
        score: Levenshtein(location.pathname, n.uri),
      })
    })

    setList(levenshteinList)
  }, [])

  return (
    <Layout>
      <div className="py-lg-5 container py-3">
        <div className="row justify-content-center">
          <div className="col-lg-8 pb-lg-5 pt-10 pb-3">
            <div
              className="rounded-3xl py-[10px] px-8"
              style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.3);' }}
            >
              <div className="flex flex-col items-center justify-center sm:flex-row">
                <Computer className="w-[300px]" />
                <div className="sm:ml-8">
                  <h1 className="font-primary text-3xl font-bold text-secondary">
                    404 - pagina niet gevonden
                  </h1>
                  <h3 className="font-bold">
                    Het lijkt erop dat deze pagina niet (meer) bestaat.
                  </h3>
                  <div className="py-3">
                    <p className="mb-0">
                      Error code: <b className="text-secondary">404</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex w-11/12 flex-col items-center justify-center">
              <div>
                <NavLink
                  className="text-lg font-bold underline transition-all hover:text-primary"
                  to="/"
                >
                  Ga terug naar de homepage
                </NavLink>
              </div>
              <div className="py-2 font-bold text-secondary">OF</div>
              <p className="mb-4 text-sm font-bold">
                kies uit één van deze paginas die wellicht lijken op de pagina
                waar je naar op zoek bent.
              </p>
              <div className="max-h-[300px] overflow-x-hidden overflow-y-scroll rounded-xl bg-grey p-5">
                {list
                  .sort((a, b) => a.score - b.score)
                  .map((n, index: number) => (
                    <div key={index}>
                      <NavLink to={n.uri}>{n.title}</NavLink>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
